<template>
  <div class="index">
    <img src="@/assets/img/activity/page-banner.png" class="banner" />

    <div class="activity">
      <img src="@/assets/img/activity/test-title.png" class="activity__header" />

      <ul class="activity__desc">
        <li>
          <p>请准备好<span>草稿纸</span>以及<span>20分钟以上</span>的安静时间</p>
        </li>
        <li>
          <p>考虑普遍的掌握进度，本月测试将重点放在高数(上)，<span>允许翻阅教材</span>，但过长的答题时间会影响得分噢 ~</p>
        </li>
        <li>
          <p>全网考生段位分布将在测试发行7日后公布，请关注<span>“无穹解题”公众号</span>获取推送</p>
        </li>
      </ul>

      <img src="@/assets/img/activity/slogan.png" class="text" />

      <p class="desc">
        当前已有<span>{{ number }}</span>人进行了测试
      </p>

      <div>
        <img class="btn" src="@/assets/img/activity/page-btn.png" @click="startActivity" />
      </div>

      <p class="goResult">
        若你已测试过了，请<span @click="visible = true">点击这里</span>
      </P>
    </div>

    <van-overlay :show="visible" @click="handleSubmit">
      <div class="wrapper" @click.stop>
        <p>请输入您参与测试的手机号</p>
        <van-field v-model="mobile" type="tel" placeholder="请输入手机号" />

        <van-button round class="submitBtn" @click="handleSubmit" type="primary">确定</van-button>

        <van-icon size="40" color="#6d6f70" name="clear" @click="close" />
      </div>
    </van-overlay>

  </div>
</template>

<script type="application/ecmascript">
import { Toast } from 'vant';
import wxSDKMixins from './mixins';

export default {
  mixins: [wxSDKMixins],
  data () {
    return {
      visible: false,
      mobile: '',
      number: 0
    }
  },
  methods: {
    computedNumber () {
      setInterval(() => {
        const random = parseInt(Math.random() * 10)
        this.number += random
      }, 2000);
    },

    // 开始活动
    startActivity () {
      this.$router.push('/activity-topic');
    },
    async handleSubmit () {
      const phoneRegexp = /^(1[3-9][0-9])\d{8}$/
      if (!phoneRegexp.test(this.mobile)) {
        Toast('请输入正确的手机号');
        return
      }
      try {
        const ret = await this.$api.activityGetResult({
          param: {
            username: this.mobile
          }
        });
        // 未做过题
        this.$store.commit('updateMobile', this.mobile);
        if (ret.status === 301) {
          Toast('您未参加过测试')
        } else {
          this.$router.push(`/activity-result?mobile=${this.mobile}`);
        }
      } catch (err) {
        console.error(err);
      }

    },
    close () {
      this.visible = false
      this.mobile = ''
    }
  },
  mounted () {
    const defaultTime = 1626937713311
    const diff = parseInt((+new Date() - defaultTime) / 150000)
    this.number = diff + 400

    this.computedNumber()

    this.initShare()
  }
}
</script>

<style lang="less" scoped>
.index {
  min-height: 100vh;
  background: #415FD5;
}
.banner {
  height: 151px;
  background: #2c3e50;
}

.activity {
  background: #415FD5;
  position: relative;
  padding-top: 37px;

  &__desc {
    list-style: initial;
    text-align: left;
    border: 2px dashed rgba(255, 255, 255, 0.6);
    padding: 34px 10px 6px 34px;
    margin: 0 17px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
     > li {
       color: #F9C200;
       font-size: 14px;
       font-weight: 400;
       line-height: 20px;
       letter-spacing: 1px;
       margin-bottom: 12px;
       > p {
         color: white;
         > span {
           font-weight: bold;
           color: #F9C200;
         }
       }
    }
  }

  &__header {
    width: 141px;
    height: 38px;
    position: absolute;
    left: calc((100vw - 141px) / 2);
    top: 20px;
  }

  .text {
    margin: 27px auto 11px auto;
    height: 29px;
  }

  .desc {
    text-align: center;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.7);
    > span {
      color: #FF7F44;
      font-weight: bold;
    }
  }

  .icon {
    width: 16px;
    height: 16px;
    margin-top: 9px;
  }

  .btn {
    width: 126px;
    height: 126px;
    margin: -8px 0 0;
  }

  .goResult {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
    padding-bottom: 24px;
    margin-top: -8px;
    > span {
      color: white;
      text-decoration: underline;
    }
  }
}

.wrapper {
  width: 289px;
  margin: 161px auto 0;
  > p {
    font-size: 18px;
    font-weight: 600;
    color: #F7F8FB;
    margin-bottom: 24px;
  }
  .submitBtn {
    width: 289px;
    height: 40px;
    background: #F9C200;
    font-size: 18px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 40px;
    margin: 24px 0 80px;
    border: none;
  }
}
</style>
